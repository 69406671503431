.togglebuttons {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1rem;
}

.togglebuttons .label {
	color: white;
	font-size: 1.75rem;
	margin-left: 0.5rem;
}

.togglebuttons .otherlabel {
	color: white;
	font-size: 1.75rem;
	margin-left: 0.5rem;
}

.togglebuttons button {
	height: 3rem;
	width: 3rem;
	font-size: 1rem;
	border-radius: 0;
	border: 4px solid #e9e9ed;
}

.togglebuttons button.active {
	background: #bbb;
}

