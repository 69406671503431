.security {
	color: white;
	font-size: 2rem;
	margin: 0.25rem;
	max-width: 19rem;
}

.security .row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	margin: 1rem 0;
}

.security .arm,
.security .lockbuttons {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
}

.security .arm button,
.security .lockbuttons button {
	height: 3rem;
	width: 33%;
	font-size: 1rem;
	border-radius: 0;
	border: 4px solid #e9e9ed;
}

.security .lockbuttons button {
	width: 25% !important;
}

.security .arm button.active,
.security .lockbuttons button.active {
	background: #bbb;
}

.security .lock .lockstate {
	font-size: 1.5rem;
	margin-left: 0.5rem;
}
