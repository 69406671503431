.updownnum {
	margin: 0.5rem 0;
	width: 100%;
	display: flex;
	flex-direction: row;
}

.updownnum button {
	padding: 0;
	height: 3rem;
	width: 3rem;
}

.updownnum > .middle {
	text-align: center;
	padding: 0.5rem;
	width: 100%;
}
