.dashboard {
	display: flex;
	flex-wrap: wrap;
}

.dashboard-stats {
	width: 25rem;
}

.dashboard-grid {
	width: 50rem;
}

.dashboard p {
	color: white;
	font-size: 2rem;
	margin: 0.25rem;
}

.dashboard p.small {
	font-size: 1.75rem;
}

.dashboard .sparkline {
	display: inline-block;
	width: 6rem;
}

.the-grid {
	display: flex;
	flex-wrap: wrap;
}

.grid-item {
	flex-basis: 11.1111%;
	border: 1px solid white;
	padding: 5px;
	text-align: center;
}
