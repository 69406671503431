.dimmer {
	margin: 1rem 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	font-size: 1rem;
}

.dimmer button {
	padding: 0;
	height: 3rem;
	width: 3rem;
}

.dimmer > .middle {
	width: 100%;
}

.dimmer > .middle input {
	margin: auto 0;
	height: 50%;
	width: 100%;
}

.dimmer > .middle .label {
	position: relative;
	bottom: 0;
	width: 100%;
	text-align: center;
}
