.thermo {
	color: white;
	font-size: 2rem;
	margin: 0.25rem;
	max-width: 19rem;
}

.thermo .small {
	font-size: 1.75rem;
}

.thermo .row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	margin: 1rem 0;
}

.thermo .row .spacetemp {
	font-size: 4rem;
	margin-right: 0.5rem;
}

.thermo .modes,
.thermo .fan {
	width: 6rem;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: center;
}

.thermo .modes button,
.thermo .fan button {
	height: 3rem;
	width: 3rem;
	font-size: 1rem;
	border-radius: 0;
	border: 4px solid #e9e9ed;
}

.thermo .modes button.active,
.thermo .fan button.active {
	background: #bbb;
}

.thermo .fan .label {
	margin-right: 0.5rem;
}

.thermo .fan .fanstate {
	margin-left: 0.5rem;
}
