* {
	box-sizing: border-box;
}

body {
	background-color: #212121;
	color: white;
	margin: 0;
}

.app {
	display: flex;
	flex-direction: row;
}

.app > .right {
	width: 100%;
	margin-left: 0.5rem;
}

.app > .left button {
	display: block;
	height: 4rem;
	width: 4rem;
	font-size: 2rem;
	border-radius: 0;
	border: 0;
	margin-bottom: 0.5rem;
}

.app > .left button.selected {
	background: #bbb;
}

.plugs {
	color: white;
	font-size: 2rem;
	margin: 0.25rem;
}
