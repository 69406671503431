.solar .container {
	max-width: 40rem;
	margin: 0 auto;
}

.solar .panels:after {
	content: "";
	display: table;
	clear: both;
}

.solar .panel {
	float: left;
	width: 23%;
	padding-bottom: 20%;
	border-radius: 5%;
	border-style: solid;
	border-width: 0.25rem;
	position: relative;
	margin: 1%;
	box-sizing: border-box;
	color: black;
}

.solar .panel-label {
	position: absolute;
	color: white;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 2rem;
	width: 110%;
	text-align: center;
}

.solar p {
	color: white;
	font-size: 2rem;
	margin: 0.25rem;
}

.recharts-wrapper p {
	color: initial;
	font-size: initial;
}
